
.before-table-container{

    max-height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
}

.before-table-container::-webkit-scrollbar {
    display: none;
}

.flexible-table {
    border-spacing: 0;
    font-family: Inter, Roboto, Arial;
    border-collapse: collapse;
    width: 100%;
    position: relative;
    margin-bottom: 80px;
}

.flexible-table tr {
    font-weight: 400;
    font-size: 14px;
}

.flexible-table .title {
    box-sizing: border-box;
    position: relative;
    color: rgb(98, 112, 132);
    border-bottom: 2px solid rgb(235, 237, 240) !important;
    padding: 8px;
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

}

.flexible-table .sortable-title{
    cursor: pointer;
}

.flexible-table .sortable-title:hover{
    background-color: #f5f9ff;
}

.flexible-table .icons-holder {

    position: absolute;
    right: 0;
    top: 0;
    /*margin-right: 5px;*/
    display: flex;
    background: white;
    height: 100%;
    width: 0;
    opacity: 0;
}

.flexible-table .title:hover .icons-holder{
    width: auto;
    opacity: 1;
}

.flexible-table .icon-holder {
    width: 20px;
    height: 15px;
    cursor: pointer;
    opacity: 0.15;
    margin: auto 15px auto 10px;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexible-table .open-with {
    transform: scale(0.8);
    cursor: grab;
}

.flexible-table .icon-holder svg {
}

.flexible-table .icon-holder:hover {
    opacity: 0.5;
    transform: scale(1);
}

.flexible-table .row {
    border-bottom: 1px solid rgb(235, 237, 240) !important;
    box-sizing: border-box;
    height: auto;
}

.flexible-table .clickable-row {
    cursor: pointer;
}

.flexible-table .clickable-row:hover {
    background-color: rgba(0, 102, 255, 0.03) !important;
}

.flexible-table .cell {
    padding: 8px;
}

.flexible-table .permanent-cell {
}

.flexible-table .drop-area {
    outline: black 2px dashed;
}

.add-column-flexible-table {
    width: 20px;
    height: 15px;
    position: absolute;
    transform: translate(100%, 35%);
    margin-right: 15px;
    top: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#first-add-column{
    transform: translate(0, 35%);
    right: unset;
    left: 0;
    color: rgb(98, 112, 132);
}

.add-column-flexible-table:hover {
    opacity: 1;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown-column-select {
    background: white;
    width: 200px;
    position: fixed;
    transform: translateX(-50%);
    z-index: 10000;
    border-radius: 3px;
    -webkit-box-shadow: 0px 7px 21px 0px rgba(34, 60, 80, 0.18);
    -moz-box-shadow: 0px 7px 21px 0px rgba(34, 60, 80, 0.18);
    box-shadow: 0px 7px 21px 0px rgba(34, 60, 80, 0.18);
}

.dropdown-column-select ul{

    overflow-y: auto;
    display: block;
    max-height: 280px;
    animation: appear-column-select 0.2s ease-in-out;
}

@keyframes appear-column-select {
    from {
        max-height: 0px;
        overflow: hidden;
    }
    to {
        max-height: 280px;
        overflow-y: auto;
    }
}

.dropdown-column-select li {
    padding: 5px 10px;
    cursor: pointer;
    transition: 0.15s;
    font-size: 14px;
}

.dropdown-column-select li:hover {
    background: rgba(0, 0, 0, 0.05);
}

.dropdown-column-select .settings{

    padding: 10px 10px;
    display: flex;
    align-items: center;
}

.dropdown-column-select .settings svg{
    opacity: 0.5;
    cursor: pointer;
}

.dropdown-column-select .settings svg:hover{
    opacity: 0.8;
}