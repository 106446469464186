.vns-main-box {
    position: absolute;
    width: 600px;
    bottom: 120px;
    right: 0;
    padding: 2px;
    border-radius: 7px;
    margin: 0 30px 10px 0;
    z-index: 2000000;
    background: #f3f5ff;
    -webkit-box-shadow: 0px 7px 20px 2px rgba(34, 60, 80, 0.18);
    -moz-box-shadow: 0px 7px 20px 2px rgba(34, 60, 80, 0.18);
    box-shadow: 0px 7px 20px 2px rgba(34, 60, 80, 0.18);

    font: 24px "Inter, Roboto, Arial", sans-serif;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.54);
}

.vns-main-box .content-box {
    margin: 20px 30px 10px 30px;
}

.vns-main-box .content-box p {
    margin: 10px 0;
}

.vns-main-box .new {
    font: 16px "Inter, Roboto, Arial", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
}

.vns-main-box .bottom-line {
    height: 35px;
    display: flex;
    justify-content: space-between;
    margin-left: 27.5px;

    color: rgba(0, 0, 0, 0.22);
    font-weight: 200;
    font-size: 14px;
    line-height: 35px;
}

.vns-main-box .checkbox {
    opacity: 0.5;

}

.vns-main-box .ok-button {
    color: #8e8e8e;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.04);
    outline: rgba(0, 0, 0, 0.3);
    outline-offset: -2px;
    padding: 0 15px;
    margin: 0 10px;
    border-radius: 7px;
    transition: 0.4s;
    cursor: pointer;
}

.vns-main-box .ok-button:hover {
    background: rgba(0, 0, 0, 0.08);
}

.vns-main-box .arrow-container {
    margin: 0 5px 0px 0;
    display: flex;
    justify-content: flex-end;
}

.vns-main-box .arrow-container svg {
    margin: 4px 2px;
    color: #a7a7a7;
    transition: 0.35s;
    cursor: pointer;
}

.vns-main-box .arrow-container svg:hover {
    color: #656565;
    transform: scale(1.2);
}

.vns-main-box .news-count-shower {
    color: #a7a7a7;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    margin: 0 4px 0 0px;
}


.vns-main-box-appear {
    animation: appear ease-in-out 4s
}

.vns-main-box-disappear {
    animation: disappear ease-in-out 0.4s;
    right: -700px
}


@keyframes disappear {
    0% {
        right: 0
    }
    100% {
        right: -700px
    }
}

@keyframes appear {
    0% {
        right: -700px
    }
    90% {
        right: -700px
    }
    100% {
        right: 0
    }
}

.vns-window-roof {
    height: 35px;
    display: flex;
    justify-content: right;
    position: absolute;
    right: 0;
}

.vns-window-roof .cross {
    height: 100%;
    width: 35px;
    color: #c1c1c1;
    cursor: pointer;
}

.vns-window-roof .cross:hover svg {
    background: rgba(0, 0, 0, 0.1);
    color: white;
}

.cross svg {
    display: block;
    margin: 4px auto auto auto;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    transition: 0.35s;
}

