.croc {
    z-index: 1501;
    position: absolute;
    top: 70%;
    left: 100%;
    transform: translate(-50%, -50%);
    transform: rotate(10deg);
}

#crocAppear {
    animation: appear 8s ease-in-out;
}

@keyframes appear {
    from {
        transform: rotate(70deg);
        left: 100%;
    }
    24% {
        transform: rotate(10deg);
        left: 87%;
    }
    76% {
        transform: rotate(10deg);
        left: 87%;
    }
    to {
        transform: rotate(70deg);
        left: 100%;
    }
}

#crocsWords {
    z-index: 1502;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
    font-size: 10pt;
    text-align: center;
    color: white;
    background-color: rgba(45, 61, 83, 0);
    padding: 25px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 35px 35px 0px 35px;
    box-shadow: 0px 0px 3px #888888;

    animation: textAppear 8s ease-in-out;
}

@keyframes textAppear {
    from {
        left: 100%;
        font-size: 10pt;
        top: 100%;
        background-color: rgba(45, 61, 83, 0);
    }
    14% {
        left: 82%;
        top: 74%;
        font-size: 30pt;
        background-color: rgba(45, 61, 83, 1);
    }
    76% {
        left: 82%;
        top: 76%;
        font-size: 30pt;
        background-color: rgba(45, 61, 83, 1);
    }
    to {
        left: 100%;
        top: 100%;
        font-size: 10pt;
        background-color: rgba(45, 61, 83, 0);
    }
}