.anekdoter-getting-ready-to-come-up{
    transform: translateX(-150%);
}

.anekdoter{
    background: rgb(209,255,255);
    background: linear-gradient(128deg, rgba(209,255,255,1) 0%, rgba(255,235,190,1) 100%);
    margin: 20px 50px;
    max-width: 500px;
    padding: 15px 0px 10px 0px;
    position: absolute;
    left: 0;
    bottom: 0%;
    z-index: 500000;
    transition: 0.5s;
    border-radius: 5px;
    box-shadow: 0 5px 15px 5px rgba(0,0,0,0.15);
}

.hidden-anekdot{
    bottom: -10%;
    opacity: 0%;
}

.anekdoter .cross{
    display: inline-block;
    float: right;
    transition: 0.2s;
}

.anekdoter .cross:hover{
    background: white;
    opacity: 0.2;
    cursor: pointer;
    border-radius: 20px;
}

.anekdoter .header h3{
    display: inline-block;
    margin: 0 0 0 30px;
    width: 80%;

    color: #454545;
}

.anekdoter .header{
    padding-right: 15px;

}

.anekdoter .content{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.03) 0%, rgba(0,0,0,0) 100%);
    padding: 15px 15px 15px 30px;
    margin: 10px 0;
    color: #2d2d2d;
}